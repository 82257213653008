import Vue from 'vue'
import { IrisGetters, IrisState } from '@iris/store/types'
import moment, { DurationInputObject } from 'moment'
import { Payment } from '@iris/store/payments/types'
import DeferredDepositField from './DeferredDepositField.vue'

export default Vue.extend({
  components: {
    DeferredDepositField
  },
  computed: {
    isSubscriptionType (): boolean {
      return (this.$store.state as IrisState).finance.paymentMethod === 'APS' || (this.$store.state as IrisState).finance.paymentMethod === 'APS_SUBSCRIPTION' || (this.$store.state as IrisState).finance.paymentMethod === 'PAYG'
    },
    oneMonthFromNow ():string {
      return (this.$store.getters as IrisGetters)['payments/deferredDepositMaxDate'].format('YYYY-MM-DD')
    },
    tomorrowsDate ():string {
      return (this.$store.getters as IrisGetters).moment().add(1, 'day').format('YYYY-MM-DD')
    },
    maximumDateAsWords (): string {
      return '1 month'
    }
  },
  methods: {
    parseAndUpdateDeferredDepositDate (this: Vue & {internalValue: Payment}, dateString?: string) {
      if (dateString) {
        let date = moment(dateString, ['YYYY-MM-DD', 'D/M/YYYY'])
        if (date.isValid()) {
          this.$set(this.internalValue, 'dueOn', date.format('YYYY-MM-DD'))
        }
      } else {
        this.$set(this.internalValue, 'dueOn', undefined)
      }
    }
  }
})
