import { IrisState } from './types'
import { Store } from 'vuex'
import { Options, Storage } from 'vuex-persistedstate'
import { STEP_PRIVACY_PROMPT } from '@iris/constants'

export const reducer = (state: IrisState): IrisState => {
  return {
    ...state,
    submitting: false,
    submitMessage: null,
    flashMessage: null,
    timeDifference: 0,
    apiException: null,
    documents: state.documents.map(document => {
      if (document.uploading) { // only reset some fields if document is actively uploading
        return {
          ...document,
          uploadError: 'Page was reloaded',
          uploadProgress: null,
          uploading: false
        }
      } else {
        return document
      }
    })
  }
}

export const setState: Options['setState'] = (key: string, state: typeof Store | IrisState, storage: Storage) => {
  // don't persist in signing mode
  if (!('step' in state) || (state.step > STEP_PRIVACY_PROMPT && !state.documentationOnlyMode)) {
    return storage.setItem(key, JSON.stringify(state))
  }
}

/**
 * Quick hack to format number
 * @param phoneNumber 
 * @returns e164 formatted phone
 */
export const formatMobilePhone = (phoneNumber: string): string => {
  // remove all non number and non + characters
  phoneNumber = phoneNumber.replaceAll(/[^0-9+]/g, '')
  // if phone number starts with a 0 replace that with a 44 regex will only replace the first character 0
  phoneNumber = phoneNumber.replace(/^0/, '44')
  // put a + on the start if not already there
  if (!phoneNumber.startsWith('+')) {
    phoneNumber = '+' + phoneNumber
  }
  return phoneNumber
}
